
.LoginForm{
    padding: 60px 80px;
    width: 600px;
}

.loginButton{
    text-align: center;
    padding-top:32px;
}

.loginButton button{
    width: 60%;
    text-align: center;
}

@media (max-width: 767px){
    .LoginForm{
        width: 90%;
        padding: 60px 32px
    }
}