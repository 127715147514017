.timeLine{
    margin-top: 20px;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
}

.timeLine .row{
    margin: 0;
}
.timeLine>.col{
    padding: 0;
}
.timeLineItemDraw{
    
    height: 40px;

}
.timeLineItemText{
    text-align: center;
}
.timeLineItemDraw .col{
    padding: 0;
    align-items: center;
    display: flex;
    position: relative;
}

.timeLineItemDraw{
    width: 100%;
}
.timeLineItemDraw .col .left, .timeLineItemDraw .col .right{
    width: 50%;
    height: 2px;
    background-color: grey;
}

.timeLineItemDraw .col .point{
    width: 16px;
    height: 16px;
    border-radius: 16px;
    background-color: white;
    border: solid grey 2px;
}

.timeLine>.col:first-child .left, .timeLine>.col:last-child .right{
    background: none;
}

.logoLayout{
    text-align: center;
}
.logoLayout img{
    max-height: 80px;
}