.order-bottom-button{
    padding-top:40px;
    text-align: center;
}

.order-bottom-button button{
    width: 60%;
    min-width: 140px;
}

.timeButtonGroup {
    overflow: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    width: 560px;
    margin-left: 50px;
    margin-right: 50px;
    justify-content: start;
}
.timeButtonGroup button{
    width: 100px;
    margin-left: 6px;
    margin-right: 6px;
}

.FormLayout ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
}
.FormLayout ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0,0,0,.5);
    -webkit-box-shadow: 0 0 1px hsla(0,0%,100%,.5);
}
.CategoryGroup{
    padding-bottom: 0;
}
.CategoryGroup button{
    margin :0 2px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    /*border-bottom: none;*/
}

.GroupBody{
    padding: 24px;
    border: solid #cfcfcf 1px;
    margin-top:-1px;
}

.MenuGroup button{
    border:none;
    background-color: #efefef;
    padding: 4px 16px;
    border-radius: 16px;
}
