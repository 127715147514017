.btn-primary{
    background-color: #3574e3;
}

.btn-warning{
    color: #555555;
    background-color: bisque;
}

.btn-grey{
    color: #434343;
    border: solid #434343 1px;
    background-color: white;
}
.btn-grey:hover, .btn-grey:focus, .btn-grey:active{
    color: white !important;
    border: solid #434343 1px !important;
    background-color: #434343 !important;
}

.btn-reserve:hover, .btn-reserve:active{
    background-color: #f07700 !important;
    opacity: 0.7;
    
}
.btn-reserve{    
    border: none;
    background-color: #f07700;
    padding: 12px 0;
    border-radius: 48px;
    font-size: 20px;
}
.btn-reserve:disabled{
    border: none;
    background-color: #f07700;
}