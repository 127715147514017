.react-calendar{
    margin-top:48px;
    padding: 24px;
    background-color: #ffffff;
    border: none;
    width: 560px;
    margin-left: auto;
    margin-right: auto;border-radius: 24px;
    box-shadow: 0px 0px 5px 2px #efefef;
}
.react-calendar__month-view__weekdays, .react-calendar__month-view__days{
    row-gap: 16px;
    justify-content: space-around;
}

.react-calendar__month-view__weekdays button,
.react-calendar__month-view__days button {
    flex: 0 0 9.5% !important;
    margin-left:2%;
    margin-right:2% !important;
}
.react-calendar button {
    width: 50px;
    height: 50px;
    border-radius: 50px;
    color: #717171;
}
.react-calendar__tile:disabled {
    color:#e7e7e7;
    background: none !important;
}
.react-calendar__tile:enabled:hover {
    background-color: #f07700;
}
.react-calendar__tile--now {
    background: none !important;
}
abbr[title]{
    color: #b8b8b8;
    text-decoration: none;
}
.react-calendar__navigation{
    height: 96px;
    align-items: center;
    margin-bottom: 0;
}
.react-calendar__month-view__weekdays{
    height: 40px;
    align-items: center;
    font-size: 16px;
}
.react-calendar__navigation__label{
    font-size: 18px;
}

.react-calendar__tile--active{
    background-color: #f07700 !important;
    color: #bcbcbc !important;
}
/* 

.react-calendar button:hover {
    color: grey;
}
.react-calendar__tile--active, .react-calendar__tile--active:enabled:hover, .react-calendar__tile--active:enabled:focus{
    background-color: white;
    color: black;
}

.react-calendar .react-calendar__month-view__days__day--neighboringMonth{
    color: grey;
} */


@media (max-width: 767px){
    .react-calendar__month-view__weekdays, .react-calendar__month-view__days {
        row-gap: 0;
    }

    .react-calendar__month-view__weekdays button,
    .react-calendar__month-view__days button {
        flex: 0 0 14.03% !important;
        margin-left:0;
        margin-right:0 !important;
        height: 40px;
    }
}