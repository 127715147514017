body{
    font-family: "Lexend Deca",Helvetica,Arial,sans-serif;
    background-image: url('../../public/img/back_spec.jpg');
    color: #626262;
    /* background-color: #f5f8fa; */
}
.TopLayOut {
    margin-top: 40px;
}
.FormLayout{
    max-width: 850px;
    margin-left: auto;
    margin-right: auto;
    background-color: #fcfcfc;    
    margin-top: 40px;
    padding:  32px 64px;
    box-shadow: rgba(45, 62, 80, 0.12) 0px 1px 5px 0px;
    border-radius: 3px;
}

.row{
    margin-bottom: 24px;
}

.CategoryGroup button, .MenuGroup button{
    margin-bottom:4px;
    margin-right: 4px;
    color: #494949;
    border: solid #bdbdbd 1px;
    background-color: white;
}

.CategoryGroup button:hover, .MenuGroup button:hover{
    color: black;
    border: solid black 1px;
}

.timeButtonGroup {
    padding-top: 10px;
    text-align: center;

}

.timeButtonGroup button{
    width: 80%;
    margin-bottom: 10px;
}


.timeButtonGroup button{
    border: solid #f90000 1px;
    background-color: #ff4444;
    color: white;
}

.timeButtonGroup button.apply{
    border: solid #3d8100 1px;
    background-color: #138556;
    color: white;
}

.timeButtonGroup button:hover{
    border: solid #f90000 1px;
    background-color: #ffbebe;
}
.timeButtonGroup button.apply:hover{
    border: solid #3d8100 1px;
    background-color: #ceffd6;
}
.timeButtonGroup button.apply.active{
    border: solid #3d8100 1px;
    background-color: #a4ffd9;
    color: #09512d;
}

.timeButtonGroup button.active{
    border: solid #f90000 1px;
    background-color: #ffbebe;
    color: #f90000;
}


.bottomButtonGroup {
    padding-top: 32px;
    margin-bottom: 0;
}
.bottomButtonGroup .col{
    display: flex;
    justify-content: space-between;
}
.bottomButtonGroup button{
    padding: 8px 40px;
}


.was-validated .form-control:valid{
    background-image: none;
    border-color: var(--bs-border-color);
}
.was-validated .form-control:valid:focus{
    box-shadow: 0 0 0 0.25rem rgba(13,110,253,.25);
    border-color: #86b7fe;
}

.form-check-input.is-valid~.form-check-label, .was-validated .form-check-input:valid~.form-check-label{
    color: black;
}
.form-check-input.is-valid, .was-validated .form-check-input:valid{
    border-color: var(--bs-border-color);
}
.form-check-input.is-valid:checked, .was-validated .form-check-input:valid:checked{
    background-color: #0d6efd;
}


.completeForm h4{
    margin: 40px 0;
    text-align: center;
}
.completeForm h5{
    margin: 32px 0;
    text-align: center;
}
.completeForm p{
    margin-left: 20px;
}
.completeForm p>button:disabled{
    padding: 4px;
    margin-left: 8px;
    background-color: white;
    border:solid grey 1px;
    color: #000000;
}

.completeForm span{
    font-size: 24px;
}
.completeForm span.apply{
    color: #00af60;
}
.completeForm span.request{
    color: #f99634;
}

.bottomButtonRow{
    width: 100%;
    padding:48px 0;
    text-align: center;
    
}

.bottomButtonRow>button{
    padding: 16px 0;
    min-width: 240px;
}

.loadMask{
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #000000;
    opacity: 0.5;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.react-confirm-alert-button-group{
    justify-content: end;
}

.InputGroupLayout{
    border: solid #e3e3e3 1px;
    border-radius: 8px;
    overflow: hidden;
}
.InputGroupLayout .col{
    padding:0px;
}

.InputGroupLayout .InputGroupTitle{
    background-color: #f99634;
    color: white;
    align-items: center;
    padding: 10px 28px;
}
.InputGroupLayout .InputGroupBody{
    padding: 24px 32px;
}
.InputGroupLayout .commentLayout>span{
    color: #f99634;
    margin-left:12px;
    margin-right: 8px;
}

.InputGroupLayout .commentLayout>input,
.InputGroupLayout .commentLayout>select{
    margin-top: 8px;
}

.InputGroupLayout .form-check{
    width: 133px;
    text-align: center;
    border: solid #cfcfcf 1px;
    border-radius: 32px;
    padding: 4px 24px 4px 33px;
}
.InputGroupLayout .form-check-input:checked {
    background-color: #f99634;
    border-color: #f99634;
}

.Bglabel{
 
    background-color: #f99634;
    color: white;
    align-items: center;
    padding: 10px 28px;   
    border-radius: 8px;
    margin-bottom: 48px;
}

.completeRow{
    margin: 0 24px;
    padding: 8px 12px;
    display: flex;
    border-bottom: dashed #7f7f7f 1px;
    align-items: center;
}
.completeRabel{
    width: 30%;
}
.completeRabel span{
    color: #f99634;
    margin-right: 8px;
}

.completeContent{
    width: 70%;
}
.CategoryGroup button>div{
    display: none;
}

@media (max-width: 767px){
    .completeForm h4, .completeForm h5{
        display: none;
    }

    .container{
        width: 90%;
        margin-left:auto;
        margin-right: auto;
        margin-bottom: 20px;
        padding: 20px 32px;
        margin-top:0;
    }
    .InputGroupLayout .InputGroupBody {
        padding: 12px 12px;
    }
    .completeRow {
        margin : 0 4px;
    }
    .completeRabel{
        width: 45%;
    }
    
    .completeContent{
        width: 55%;
    }
    .order-bottom-button button {
        width: 90% !important;
        min-width: 140px;
    }
    .CategoryGroup{
        display: flex;
        flex-wrap: wrap;
        row-gap: 8px;
        justify-content: space-between;
        margin-bottom: 20px;
    }

    .CategoryGroup button{
        width: 48%;
        border: none;
        border-radius: 4px !important;
        padding: 12px 0;
        position: relative;
    }

    .CategoryGroup button:hover, .MenuGroup button:hover {
        border: none;
    }

    .GroupBody{
        border: none !important;
        padding: 0 !important;
        padding-top:12px !important;
    }
    .CategoryGroup button>div{
        
        display: flex;
        position: absolute;
        top:12px;
        right: 12px;
    }
    .timeButtonGroup{
        margin:0 !important;
    }
    .timeButtonGroup button {
        width: 90px !important;
        margin-left: 4px !important;
        margin-right: 4px !important;
    }
}